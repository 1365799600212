<template>
    <div id="contenedorInicio">
        <h1>Listado de alérgenos</h1>
        <div class="card" v-for="producto in productos" :key="producto.nombre">
            <div style="display: flex; align-items: center; border-bottom: 1px solid darkgray; padding: 5px;">
                <img style="margin-right: 5px;" :src="producto.imagen" width="50px" />
                <h2>{{ producto.nombre }}</h2>
            </div>
            <div style="display:flex; flex-wrap: wrap; justify-content:center;">
                <div v-for="alergeno in producto.alergenos" :key="producto.nombre + alergeno[0]"
                    style="display:flex; flex-direction: column; margin: 10px; margin-bottom: 0px; align-items:center;">
                    <img v-if="imagenExiste(alergeno)" :src="require('@/assets/img/alergenos/' + alergeno[0] + '.png')"
                        width="50px" />
                    <b>{{ alergeno[1] }}</b>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FichasTecnicas',
    data() {
        return {
            productos: []
        }
    },
    mounted() {
        this.init()
    },

    methods: {
        async init() {
            const url = process.env.VUE_APP_BACKEND_URL + '/api/obtenerAlergenos';

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        // Puedes agregar más encabezados según sea necesario
                    },
                });
                this.productos = await response.json();
                this.productos = this.productos.sort(this.compararPorOrden)
            } catch (error) {
                // Manejar errores aquí
                console.error('Error en la solicitud:', error.message);
            }
        },
        imagenExiste(img) {
            try {
                require('@/assets/img/alergenos/' + img[0] + '.png');
                return true;
            } catch (error) {
                console.log(img);
                return false;
            }
        },
        compararPorOrden(a, b) {
            if (a.orden < b.orden) {
                return -1;
            } else if (a.orden > b.orden) {
                return 1;
            } else {
                return 0;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#contenedorInicio {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    width: 100%;
    min-height: 100vh;
    text-align: center;

    h1 {
        margin: 0px;
    }

    h2 {
        margin: 0px;
    }

    h3 {
        margin: 0px;
    }

    .card {
        -webkit-box-shadow: 3px 3px 6px -1px rgba(0, 0, 0, 0.48);
        -moz-box-shadow: 3px 3px 6px -1px rgba(0, 0, 0, 0.48);
        box-shadow: 3px 3px 6px -1px rgba(0, 0, 0, 0.48);
        width: -webkit-fill-available;
        padding: 5px;
        margin: 10px;
        display: flex;
        flex-direction: column;
    }
}
</style>