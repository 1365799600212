<template>
    <div id="contenedorGeneral">
        <div id="contenedorEncuentranos">
            <transition name="el-fade-in-linear" @after-leave="mostrarIframe = true">
                <div v-if="loading">
                    <img width="100" src="@/assets/img/roscon-de-reyes.png" class="fa-fade" />
                    <p><b>Cargando...</b></p>
                </div>
            </transition>
            <transition name="el-fade-in-linear">
                <div :style="`width: 100%; transition: 1s; ${!mostrarIframe ? 'display:none;' : ''}`">
                    <h2>Encuéntranos</h2>
                    <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0"
                        @load="loading = false" marginwidth="0"
                        src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=es&amp;q=43.380280,%20-3.729964+(Horno%20Goimar)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                    <a href="https://goo.gl/maps/8wymNxUrCZAD759z7" target="_blank"><el-button color="#ffffff1c"
                            :dark="isDark"><i style="margin-right: 5px;" class="fa-regular fa-map-location-dot"></i>Abrir
                            con Google
                            Maps</el-button></a>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaginaEncuentranos',
    data() {
        return {
            loading: true,
            mostrarIframe: false,
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#contenedorGeneral {
    display: flex;
    align-items: center;
    justify-content: center;
}

#contenedorEncuentranos {
    border-radius: 5px;
    padding: 10px;
    color: white;
    transition: 0.5s;
    width: 100%;
    text-align: center;

    h2 {
        background: #ffffff1c;
        padding: 15px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-bottom: 0px;
    }

    button {
        border-top-left-radius:0px;
        border-top-right-radius: 0px;
        border-top:none;
        margin: 0px;
        width: 100%;
        padding: 25px;
        color: white;
        font-size: 1.2rem;
    }

    @media(min-width: 600px) {
        width: 75%;
    }

    @media(min-width: 900px) {
        width: 50%;
    }

    @media(min-width: 1300px) {
        width: 30%;
    }
}</style>
