<template>
    <div v-show="!drawer" id="cesta" @click="clickCesta()"><i class="fa-duotone fa-basket-shopping"></i></div>
    <el-drawer v-model="drawer" title="Cesta" :append-to-body="true" :size="drawerSize" :show-close="cerrarModal"
        :close-on-click-modal="cerrarClick">
        <el-alert v-if="cestaLocal.length == 0" title="¡Oops!" type="info"
            description="Parece que tu cesta se encuentra vacía" show-icon :closable="false" />
        <el-button @click="finalizarPedido" v-if="cestaLocal.length != 0" type="success"
            style="width: 100%; height: 50px; font-size: 2rem; margin-bottom: 10px;">Finalizar pedido</el-button>
        <div v-for="articulo in cestaLocal" :key="articulo" class="articulocesta">
            <div class="imagen"><img src="@/assets/img/roscon.png" style="width: 100%" /></div>
            <div>
                <b>Rosco {{ buscarTamano(articulo.tamano).mostrando }} {{
                    buscarRelleno(articulo.relleno).mostrando }}</b>
                <div style="margin-top: 10px;">
                    <div>
                        <el-button plain @click="articulo.cantidad--"><i
                                :class="articulo.cantidad == 1 ? 'fa-regular fa-trash-can' : 'fa-solid fa-minus'"></i></el-button>
                        <el-input style="width: 50px; margin: 0px 5px 0px 5px;"
                            input-style="text-align:center; color:black;" v-model="articulo.cantidad" placeholder="Cantidad"
                            disabled />
                        <el-button @click="articulo.cantidad++" plain><i class="fa-solid fa-plus"></i></el-button>
                    </div>
                </div>
            </div>
        </div>
    </el-drawer>
    <transition name="el-fade-in-linear" @after-leave="mostrar = true">
        <div v-if="loading" id="contenedorInicio">
            <div>
                <img width="100" src="@/assets/img/roscon-de-reyes.png" class="fa-fade" />
                <p><b>Cargando...</b></p>
            </div>
        </div>
    </transition>
    <el-collapse-transition>
        <div v-if="mostrar && step == 1" id="contenedorInicio">
            <div>
                <h1>Vamos a crear tu rosco</h1>
                <h3>Primero, elige un tamaño</h3>
                <div class="contenedortamanos">
                    <ul>
                        <li @click="seleccion.tamano = tamano.valor; cambiarPaso(2)" v-for="(tamano, index) in tamanos"
                            :key="index">
                            <img :style="`width: ${100 - 20 * index}%;`" src="@/assets/img/roscon.png" />
                            <h4>{{ tamano.mostrar }}</h4>
                            <small>Desde {{ tamano.precio_normal }} €</small>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </el-collapse-transition>
    <el-collapse-transition>
        <div v-if="mostrar && step == 2" id="contenedorInicio">
            <div>
                <h1>¡Buena elección!</h1>
                <h3>Has elegido un rosco {{ seleccion.tamano }}. Ahora, elige un relleno</h3>
                <div class="contenedorrellenos">
                    <ul>
                        <li @click="seleccion.relleno = relleno.valor; cambiarPaso(3)" v-for="(relleno, index) in rellenos"
                            :key="index">
                            <img :style="`width: 20%;`" :src="require(`@/assets/img/rellenos/${relleno.valor}.png`)" />
                            <h4>{{ relleno.valor == 'normal' ? relleno.mostrar : 'Relleno ' + relleno.mostrando }}</h4>
                            <b>{{ relleno.valor == 'normal' ? buscarTamano(this.seleccion.tamano).precio_normal :
                                buscarTamano(this.seleccion.tamano).precio_relleno }} €</b>
                        </li>
                    </ul>
                </div>
            </div>
            <el-collapse-transition>
                <el-button @click="seleccion = { relleno: '', tamano: '', cantidad: 1 }; cambiarPaso(1)" type="warning"><i
                        class="fa-regular fa-hand-back-point-left"></i> Cambiar tamaño</el-button>
            </el-collapse-transition>
        </div>
    </el-collapse-transition>
    <el-collapse-transition>
        <div v-if="mostrar && step == 3" id="contenedorInicio">
            <div>
                <h1>¡Ya casi lo tenemos!</h1>
                <h3>Has elegido un rosco {{ buscarTamano(seleccion.tamano).mostrando }} {{
                    buscarRelleno(seleccion.relleno).mostrando }}. ¿Cuántos vas a querer?</h3>
                <div class="contenedorrellenos">
                    <ul style="flex-wrap: nowrap;">
                        <li @click="seleccion.cantidad > 1 ? seleccion.cantidad-- : seleccion.cantidad = 1">
                            <h1>-</h1>
                        </li>
                        <li>
                            <h1>{{ seleccion.cantidad }}</h1>
                        </li>
                        <li @click="seleccion.cantidad++">
                            <h1>+</h1>
                        </li>
                    </ul>
                </div>
            </div>
            <div style="display:flex; flex-wrap: nowrap; flex-wrap: wrap; justify-content: center;">
                <el-button style="min-width: 265px; margin: 5px;"
                    @click="seleccion.relleno = ''; seleccion.cantidad = 1; cambiarPaso(2)" type="warning"><i
                        class="fa-regular fa-hand-back-point-left"></i> Cambiar relleno</el-button>
                <el-button style="min-width: 265px; margin: 5px;" @click="anadirCesta" type="success"><i
                        class="fa-regular fa-bags-shopping"></i> Añadir a la
                    cesta</el-button>
            </div>
        </div>
    </el-collapse-transition>
    <el-collapse-transition>
        <div v-if="mostrar && step == 4" id="contenedorInicio" style="width:90%;">
            <div>
                <h1>¡Eso es!</h1>
                <h3>Hemos añadido tu selección a la cesta. ¿Qué hacemos ahora?</h3>
                <div class="contenedorrellenos">
                    <ul>
                        <li @click="cambiarPaso(1)">
                            <h1><i class="fa-duotone fa-bags-shopping"></i></h1>
                            <h3 style="margin:0px">Añadir más</h3>
                        </li>
                        <li @click="finalizarPedido">
                            <h1><i class="fa-duotone fa-cart-shopping-fast"></i></h1>
                            <h3 style="margin:0px">Finalizar pedido</h3>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </el-collapse-transition>
</template>

<script>
import { ElNotification } from 'element-plus'
export default {
    name: 'PaginaPedido',
    props: {
        cesta: Array,
    },
    mounted() {
        this.cestaLocal = this.cesta;
        this.init();
        window.addEventListener('resize', this.onResize);
    },
    watch: {
        cestaLocal: {
            handler: function () {
                if (this.cestaLocal.length > 0) {
                    this.cestaLocal.forEach((articulo, index) => {
                        if (articulo.cantidad == 0) {
                            this.cestaLocal.splice(index, 1);
                        }
                    })
                }
                this.$emit('actualizarCesta', this.cestaLocal);
            },
            deep: true
        }
    },
    data: function () {
        return {
            tamanos: {},
            rellenos: {},
            loading: true,
            mostrar: false,
            seleccion: {
                tamano: '',
                relleno: '',
                cantidad: 1,
            },
            step: 1,
            drawer: false,
            width: window.innerWidth,
            cestaLocal: [],
            cerrarModal: true,
            cerrarClick: false,
            configuracion: ''
        }
    },
    computed: {
        drawerSize() {
            let size = '100%';
            if (this.width > 500) {
                size = '75%';
            }
            if (this.width > 600) {
                size = '60%';
            }
            if (this.width > 800) {
                size = '50%';
            }
            if (this.width > 1000) {
                size = '40%';
            }
            if (this.width > 1300) {
                size = '30%';
            }
            if (this.width > 1500) {
                size = '25%';
            }
            return size;
        }
    },
    methods: {
        finalizarPedido() {
            // Utiliza el router para redirigir a la página "/finalizar"
            this.$router.push('/finalizar');
        },
        async init() {
            const url = process.env.VUE_APP_BACKEND_URL + '/api/obtenerConfiguracion';

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        // Puedes agregar más encabezados según sea necesario
                    },
                });
                this.configuracion = await response.json();
                if(!this.configuracion.pedidos_activos) {
                    this.$router.push('/');
                    ElNotification({
                            title: 'Oops',
                            message: 'Los pedidos han sido cerrados.',
                            type: 'warning',
                        })
                }
            } catch (error) {
                // Manejar errores aquí
                console.error('Error en la solicitud:', error.message);
            }
            fetch(process.env.VUE_APP_BACKEND_URL + '/api/obtenerDatosRoscos')
                .then(response => response.json())
            .then(data => {
                this.tamanos = data.tamanos;
                this.rellenos = data.rellenos;
                this.loading = false;
            })
            .catch(error => {
                console.error('Error:', error);
            });

    },
    clickCesta() {
        this.drawer = !this.drawer;
        if (this.drawer) {
            this.cerrarModal = true;
            this.cerrarClick = true;
        }
    },
    anadirCesta() {
        let seleccion = Object.assign({}, this.seleccion);
        let articuloCesta = this.cestaLocal.find((art) => { return art.tamano == this.seleccion.tamano && art.relleno == this.seleccion.relleno });
        if (articuloCesta) {
            seleccion.cantidad += articuloCesta.cantidad;
            this.cestaLocal.splice(this.cestaLocal.indexOf(articuloCesta), 1);
        }
        this.cestaLocal.push(seleccion);
        this.cerrarModal = false
        this.drawer = true
        setTimeout(() => {
            this.cambiarPaso(4);
            this.seleccion = {
                tamano: '',
                relleno: '',
                cantidad: 1,
            }
            this.drawer = false;
            this.cerrarModal = true;
        }, 2000)
    },
    onResize() {
        this.width = window.innerWidth
    },
    beforeEnter() {
        // Espera un momento antes de mostrar la segunda transición
        setTimeout(() => {
            this.showFirst = false;
            this.showSecond = true;
        }, 500); // Ajusta el tiempo de espera según tus necesidades
    },
    buscarTamano(valor) {
        return this.tamanos.find((tamano) => tamano.valor === valor);
    },
    buscarRelleno(valor) {
        return this.rellenos.find((relleno) => relleno.valor === valor);
    },
    cambiarPaso(num) {
        this.step = 0;
        setTimeout(() => {
            this.step = num;
        }, 400);
    }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h1 {
    margin: 0px;
}

.articulocesta {
    border: 1px solid darkgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;

    .imagen {
        min-width: 75px;
        max-width: 75px;
        min-height: 75px;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 50% center;
        background-color: beige;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        margin-right: 10px;
    }
}

#cesta {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(0, 0, 0, 0.281);
    padding: 15px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.233);
    justify-content: flex-end;
    transition: 0.5s;
    color: white;
    font-size: 2rem;
    z-index: 9999;

    &:hover {
        background: rgba(255, 255, 255, 0.75);
        color: black;
    }
}

#contenedorInicio {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;

    .contenedortamanos {
        display: flex;
        justify-content: center;
        width: 100%;

        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0px;
            justify-content: center;

            li {
                width: 50%;
                margin: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: rgba(0, 0, 0, 0.281);
                padding: 20px;
                border-radius: 10px;
                border: 2px solid rgba(255, 255, 255, 0.233);
                justify-content: flex-end;
                transition: 0.5s;
                -webkit-touch-callout: none;
                /* iOS Safari */
                -webkit-user-select: none;
                /* Safari */
                -khtml-user-select: none;
                /* Konqueror HTML */
                -moz-user-select: none;
                /* Old versions of Firefox */
                -ms-user-select: none;
                /* Internet Explorer/Edge */
                user-select: none;
                /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

                &:hover {
                    background: rgba(255, 255, 255, 0.75);
                    color: black;
                }

                h4 {
                    margin: 0px;
                }

                img {
                    max-width: 100%;
                    margin-bottom: 10px;
                }

                @media(min-width: 480px) {
                    width: 20%;
                }

                @media(min-width: 1400px) {
                    width: 15%;
                }
            }
        }
    }

    .contenedorrellenos {
        display: flex;
        justify-content: center;
        width: 100%;

        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0px;
            justify-content: center;

            li {
                width: 50%;
                margin: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: rgba(0, 0, 0, 0.281);
                padding: 20px;
                border-radius: 10px;
                border: 2px solid rgba(255, 255, 255, 0.233);
                justify-content: flex-end;
                transition: 0.5s;
                -webkit-touch-callout: none;
                /* iOS Safari */
                -webkit-user-select: none;
                /* Safari */
                -khtml-user-select: none;
                /* Konqueror HTML */
                -moz-user-select: none;
                /* Old versions of Firefox */
                -ms-user-select: none;
                /* Internet Explorer/Edge */
                user-select: none;
                /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

                &:hover {
                    background: rgba(255, 255, 255, 0.75);
                    color: black;
                }

                h4 {
                    margin: 0px;
                }

                img {
                    max-width: 100%;
                    margin-bottom: 10px;
                }

                @media(min-width: 0px) {
                    width: 80%;
                }

                @media(min-width: 480px) {
                    width: 33%;
                }

                @media(min-width: 1400px) {
                    width: 15%;
                }
            }
        }
    }

    h1 {
        margin-bottom: 5px;
        text-align: center;
    }

    h3 {
        margin-top: 0px;
        font-weight: normal;
        text-align: center;
    }

    button {
        font-size: 1.5rem;
        padding: 20px;

        i {
            margin-right: 10px;
        }
    }
}
</style>
