<template>
    <div id="contenedorGeneral">
        <div id="contenedorEncuentranos">
            <transition name="el-fade-in-linear" @after-leave="mostrar = true">
                <div v-if="loading">
                    <img width="100" src="@/assets/img/roscon-de-reyes.png" class="fa-fade" />
                    <p><b>Cargando...</b></p>
                </div>
            </transition>
            <transition name="el-fade-in-linear">
                <div v-if="mostrar"
                    :style="`width: 100%; transition: 1s; width: 100%; transition: all 1s ease 0s; background: white; border-radius: 10px; color: #434242;`">
                    <h2>Precios</h2>
                    <div v-for="relleno in rellenos" :key="relleno.id">
                        <h3 style="margin:5px; display: flex; justify-content: center;"><img
                                style="max-height: 25px; margin-right: 10px;"
                                :src="require('@/assets/img/rellenos/' + relleno.valor + '.png')" /> Rosco {{ relleno.mostrando
                                }}</h3>
                        <table style="width: 100%; text-align:center; padding:10px;" cellpadding="5px">
                            <tr v-for="tamano in tamanos" :key="tamano.id" style="width:100%; margin: 15px;">
                                <th scope="row">{{ tamano.mostrar }}</th>
                                <td v-if="relleno.valor == 'normal'">{{ tamano.peso_normal }} g</td>
                                <td v-else>{{ tamano.peso_relleno }} g</td>
                                <td v-if="relleno.valor == 'normal'">{{ tamano.precio_normal }}€</td>
                                <td v-else>{{ tamano.precio_relleno }}€</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaginaPrecios',
    mounted() {
        this.init();
    },
    data: function () {
        return {
            tamanos: {},
            rellenos: {},
            loading: true,
            mostrar: false,
        }
    },
    methods: {
        init() {
            fetch(process.env.VUE_APP_BACKEND_URL+'/api/obtenerDatosRoscos')
                .then(response => response.json())
                .then(data => {
                    this.tamanos = data.tamanos;
                    this.rellenos = data.rellenos;
                    this.tamanos.reverse();
                    this.loading = false;
                })
                .catch(error => {
                    console.error('Error:', error);
                });

        }
    }
}
</script>
<style lang="scss" scoped>
#contenedorGeneral {
    display: flex;
    align-items: center;
    justify-content: center;
}

#contenedorEncuentranos {
    border-radius: 5px;
    padding: 10px;
    transition: 0.5s;
    width: 100%;
    text-align: center;
    color: white;

    h2 {
        background: #2f2f2f;
        padding: 15px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-bottom: 20px;
        color: white;
    }

    button {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top: none;
        margin: 0px;
        width: 100%;
        padding: 25px;
        color: white;
        font-size: 1.2rem;
    }

    @media(min-width: 600px) {
        width: 75%;
    }

    @media(min-width: 900px) {
        width: 50%;
    }

    @media(min-width: 1300px) {
        width: 30%;
    }
}</style>