<template>
    <transition name="el-fade-in-linear" @after-leave="mostrar = true">
        <div v-if="loading" id="contenedorInicio">
            <div>
                <img width="100" src="@/assets/img/roscon-de-reyes.png" class="fa-fade" />
                <p><b>Cargando...</b></p>
            </div>
        </div>
    </transition>
    <el-collapse-transition>
        <div v-if="mostrar" id="contenedorInicio">
            <div class="contenedorInicio" style="width: 100%;">
                <h1>Finalizar pedido</h1>
                <h3 v-if="step != 3">¡Ya casi estamos! Solo nos faltan unos datos más para completar tu pedido.</h3>
                <div class="container" style="width: auto;">
                    <div class="row" :style="`display:flex; align-items: stretch; flex-direction: row-reverse; ${width < 700 ? 'flex-direction: column;' : ''} padding: 10px;`">
                        <div v-if="step == 1" class="col-sm-12 col-md-5"
                            style="flex:1; background-color:white; padding:0px; border-radius: 10px 10px 0px 0px;">
                            <h2 style="margin: 0px;">Tu pedido</h2>
                            <div style="background:white; color:black; padding: 20px;">
                                <el-alert v-if="cestaLocal.length == 0" title="¡Oops!" type="info"
                                    description="Parece que tu cesta se encuentra vacía" show-icon :closable="false" />
                                <div v-for="articulo in cestaLocal" :key="articulo" class="articulocesta">
                                    <div class="imagen"><img src="@/assets/img/roscon.png" style="width: 100%" /></div>
                                    <div>
                                        {{ articulo.cantidad }} x <b>Rosco {{ buscarTamano(articulo.tamano).mostrando }} {{
                                            buscarRelleno(articulo.relleno).mostrando }}</b>
                                        <p style="margin-top: 10px;">{{ calcularPrecio(articulo) * articulo.cantidad }}€
                                            <small>({{ calcularPrecio(articulo) }}€/ud)</small>
                                        </p>
                                        <div style="margin-top: 10px;">
                                        </div>
                                    </div>
                                </div>
                                <hr style="border:none;">
                                <b style="margin-top:10px;">TOTAL:</b> {{ calcularTotal() }}€
                            </div>
                        </div>
                        <div v-if="step != 1" class="col-sm-12 col-md-5"
                            style="flex:1; background-color:white; padding:0px; border-radius: 10px 10px 0px 0px;">
                            <h2 style="margin: 0px;">Resumen de tu pedido</h2>
                            <div style="background:white; color:black; padding: 20px;">
                                <h3>Datos personales</h3>
                                <p style="margin-top: 0px"><b>Nombre: </b>{{ resumen.datosPer.nombre }} {{
                                    resumen.datosPer.apellidos }}</p>
                                <p><b>Teléfono: </b>{{ resumen.datosPer.telefono }}</p>
                                <p v-if="datosPer.email"><b>Email: </b>{{ resumen.datosPer.email }}</p>
                                <p><b>Recogida: </b>{{ resumen.datosPer.dia }}</p>
                                <span v-if="resumen.datosPer.dia">Podrás recoger tu pedido entre las {{
                                    buscarDia(resumen.datosPer.dia).hora_inicio.split(':')[0]
                                    + ':' + buscarDia(resumen.datosPer.dia).hora_inicio.split(':')[1] }} y las {{
                                    buscarDia(resumen.datosPer.dia).hora_fin.split(':')[0]
                                    + ':' + buscarDia(resumen.datosPer.dia).hora_fin.split(':')[1] }}</span>
                                <el-alert v-if="resumen.cesta.length == 0" title="¡Oops!" type="info"
                                    description="Parece que tu cesta se encuentra vacía" show-icon :closable="false" />
                                <h3 style="margin-top: 10px;">Pedido</h3>
                                <div v-for="articulo in resumen.cesta" :key="articulo">
                                    <div>
                                        {{ articulo.cantidad }} x <b>Rosco {{ buscarTamano(articulo.tamano).mostrando }} {{
                                            buscarRelleno(articulo.relleno).mostrando }}</b>
                                        <div style="margin-top: 10px;">
                                        </div>
                                    </div>
                                </div>
                                <hr style="border:none;">
                                <b style="margin-top:10px;">TOTAL:</b> {{ resumen.total }}€
                            </div>
                        </div>
                        <div v-if="step == 1" class="col-sm-12 col-md-7 flex-column"
                            style="flex:1; background-color:white; padding:0px; border-radius: 10px 10px 0px 0px;">
                            <h2 style="margin: 0px;">Datos de tu pedido</h2>
                            <div style="background:white; color:black; padding: 20px;">
                                <div class="row" style="margin-bottom: 20px;">
                                    <div class="col-md-6">
                                        <b>Nombre</b>
                                        <el-input v-model="datosPer.nombre" placeholder="Nombre" />
                                    </div>
                                    <div class="col-md-6">
                                        <b>Apellido</b>
                                        <el-input v-model="datosPer.apellidos" placeholder="Apellidos" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <b>Teléfono</b>
                                        <el-input type="tel" v-model="datosPer.telefono" placeholder="Teléfono" />
                                    </div>
                                    <div class="col-md-6">
                                        <b>Email</b>
                                        <el-input type="email" v-model="datosPer.email" placeholder="Email" />
                                    </div>
                                    <p style="margin-top: 10px; text-align: justify;">NOTA: Estos datos se utilizarán
                                        <b>ÚNICAMENTE</b> con el
                                        fin de contactar contigo sobre el estado de tu pedido. No te enviaremos
                                        comunicaciones comerciales ni compartiremos estos datos con nadie.
                                    </p>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" style="display:flex; flex-direction: column;">
                                        <b style="margin-bottom: 10px;">Día de recogida</b>
                                        <el-radio-group v-model="datosPer.dia"
                                            style="display:flex; align-items: center; justify-content: center;">
                                            <el-radio style="margin-bottom: 10px;" v-for="dia in dias" :label="dia.mostrar"
                                                :key="dia" border />
                                        </el-radio-group>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <el-button @click="volverAtras" type="warning"
                                            style="width: 100%; font-size: 1rem; margin-bottom: 10px;"><i
                                                class="fa-regular fa-left"></i> Atrás</el-button>
                                    </div>
                                    <div class="col-md-6">
                                        <el-button @click="siguiente" type="success"
                                            style="width: 100%; font-size: 1rem; margin-bottom: 10px;">Realizar pedido <i
                                                style="margin-left: 10px; margin-right:0px;"
                                                class="fa-regular fa-right"></i></el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="step == 2" class="col-sm-12 col-md-7 flex-column"
                            style="flex:1; background-color:white; padding:0px; border-radius: 10px 10px 0px 0px;">
                            <h2 style="margin: 0px;">Método de pago</h2>
                            <div style="background:white; color:black; padding: 20px;">
                                <h3>Elige un método de pago</h3>
                                <div @click="step = 4" class="metodopago">
                                    <img src="@/assets/img/tarjeta.png">
                                    <span><b>Pagar online con
                                            tarjeta</b><br><small>Pagar online con tarjeta. De esta forma, solo tendrás que
                                            recoger tu pedido el día que has elegido.</small></span>
                                </div>
                                <div @click="step == 3" class="metodopago">
                                    <img src="@/assets/img/dinero.png">
                                    <span><b>Pagar al recoger</b><br><small>Pagarás tu pedido al recogerlo. Recuerda que
                                            aceptamos pagos en efectivo y con tarjeta.</small></span>
                                </div>
                                <b>IMPORTANTE: </b> Elijas la opción que elijas, tu pedido ya ha sido realizado. Si
                                necesitas hacer cualquier modificación del pedido. <b>Ponte en contacto con nosotros</b>.
                            </div>
                        </div>
                        <div v-if="step == 4" class="col-sm-12 col-md-7 flex-column"
                            style="flex:1; background-color:white; padding:0px; border-radius: 10px 10px 0px 0px;">
                            <h2 style="margin: 0px;">Pagar con tarjeta</h2>
                            <div style="height: 100%; background-color: white;">
                                <iframe style="width: 100%; height: 100%; border:none;"
                                    :src="`https://pagos.hornogoimar.es/checkout.html?identificador=${identificador}`"></iframe>
                            </div>
                        </div>
                        <div v-if="step == 3" class="col-sm-12 col-md-7 flex-column"
                            style="flex:1; background-color:white; padding:0px; border-radius: 10px 10px 0px 0px;">
                            <h2 style="margin: 0px;">¡Pedido finalizado correctamente!</h2>
                            <div class="contenedorSuccess">
                                <img src="@/assets/img/comprobado.png" width="30%" />
                                <p>Tu pedido se ha completado correctamente. A continuación tienes un resumen detallado.</p>
                                <p><b>¡Gracias por confiar una vez más en nosotros!</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-collapse-transition>
</template>

<script>
import { ElNotification } from 'element-plus'
export default {
    name: 'FinalizarPedido',
    props: {
        cesta: Array,
        datosPersonales: Object,
    },
    mounted() {
        this.init();
        window.addEventListener('resize', this.onResize);
    },
    watch: {
        datosPer: {
            handler: function () {
                this.$emit('actualizarDatos', this.datosPer);
            },
            deep: true
        },
        cestaLocal: {
            handler: function () {
                this.$emit('actualizarCesta', this.cestaLocal);
            },
            deep: true
        },
    },
    data: function () {
        return {
            loading: true,
            mostrar: false,
            step: 1,
            drawer: false,
            width: window.innerWidth,
            cestaLocal: [],
            datosPer: {
                nombre: '',
                apellidos: '',
                email: '',
                telefono: '',
                dia: '',
            },
            resumen: [],
            tamanos: [],
            rellenos: [],
            dias: [],
            identificador: ''
        }
    },
    methods: {
        buscarDia(mostrar) {
            let dia = this.dias.find((dia) => { return dia.mostrar == mostrar });
            return dia;
        },
        init() {
            this.datosPer = this.datosPersonales;
            this.loading = true;
            fetch(process.env.VUE_APP_BACKEND_URL + '/api/obtenerDatosRoscos')
                .then(response => response.json())
                .then(data => {
                    this.tamanos = data.tamanos;
                    this.rellenos = data.rellenos;
                    this.dias = data.dias;
                    this.cestaLocal = this.cesta;
                    if (this.cestaLocal.length == 0) {
                        this.$router.push('/pedido');
                        ElNotification({
                            title: 'Oops',
                            message: 'No se ha detectado ningún pedido.',
                            type: 'warning',
                        })
                    }
                    this.loading = false;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },
        buscarTamano(valor) {
            return this.tamanos.find((tamano) => tamano.valor === valor);
        },
        buscarRelleno(valor) {
            return this.rellenos.find((relleno) => relleno.valor === valor);
        },
        calcularPrecio(rosco) {
            let respuesta = 0;
            let tamano = '';
            switch (rosco.relleno) {
                case 'normal':
                    tamano = this.buscarTamano(rosco.tamano);
                    respuesta = tamano.precio_normal;
                    break;
                default:
                    tamano = this.buscarTamano(rosco.tamano);
                    respuesta = tamano.precio_relleno;
                    break;
            }
            return respuesta;
        },
        calcularTotal() {
            let respuesta = 0;
            this.cestaLocal.forEach((articulo) => {
                respuesta += this.calcularPrecio(articulo) * articulo.cantidad;
            })
            return respuesta;
        },
        volverAtras() {
            this.$router.push('/pedido');
        },
        onResize() {
            this.width = window.innerWidth
        },
        async siguiente() {
            if (this.datosPer.nombre == '' || this.datosPer.apellidos == '' || this.datosPer.telefono == '' || this.datosPer.email == '' || this.datosPer.dia == '') {
                ElNotification({
                    title: 'Error',
                    message: 'Completa todos los datos para poder continuar.',
                    type: 'error',
                })
                return;
            } else {
                this.loading = true;
                this.mostrar = false;
                let data = {
                    datosPersonales: this.datosPersonales,
                    cesta: this.cesta
                }
                try {
                    const result = await fetch(process.env.VUE_APP_BACKEND_URL + '/api/realizarPedido', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    });
                    if (result.status == 200) {
                        ElNotification({
                            title: '¡Bien!',
                            message: 'Tu pedido se ha realizado correctamente. Si has especificado una dirección de correo electrónico, recibirás una confirmación en breve.',
                            type: 'success',
                        })
                        this.resumen = {
                            datosPer: Object.assign({}, this.datosPer),
                            cesta: [].concat(this.cestaLocal),
                            total: '' + this.calcularTotal()
                        }
                        this.datosPer = {
                            nombre: '',
                            apellidos: '',
                            email: '',
                            telefono: '',
                            dia: '',
                        }
                        this.cestaLocal = [];
                        this.identificador = await result.json();
                        this.step = 3
                        this.loading = false;
                    } else {
                        throw new Error('Error en la solicitud');
                    }
                } catch (error) {
                    ElNotification({
                        title: 'Error',
                        message: 'Ha ocurrido un error al enviar tu pedido.',
                        type: 'error',
                    })
                    this.step = 1
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.contenedorSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    text-align: justify;
    padding: 10px;
}

.metodopago {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    text-align: justify;
    border: 1px solid #a9a9a95e;
    border-radius: 5px;
    padding: 10px;
    transition: 0.5s;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        background-color: #a9a9a938;
    }

    img {
        max-width: 50px;
        margin-right: 20px;
    }
}

.articulocesta {
    border: 1px solid darkgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;

    .imagen {
        min-width: 50px;
        max-width: 50px;
        min-height: 50px;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 50% center;
        background-color: beige;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        margin-right: 10px;
    }
}

#contenedorInicio {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;

    h2 {
        background: #2f2f2f;
        padding: 15px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-bottom: 20px;
        color: white;
    }

    .contenedortamanos {
        display: flex;
        justify-content: center;
        width: 100%;

        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0px;
            justify-content: center;

            li {
                width: 50%;
                margin: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: rgba(0, 0, 0, 0.281);
                padding: 20px;
                border-radius: 10px;
                border: 2px solid rgba(255, 255, 255, 0.233);
                justify-content: flex-end;
                transition: 0.5s;
                -webkit-touch-callout: none;
                /* iOS Safari */
                -webkit-user-select: none;
                /* Safari */
                -khtml-user-select: none;
                /* Konqueror HTML */
                -moz-user-select: none;
                /* Old versions of Firefox */
                -ms-user-select: none;
                /* Internet Explorer/Edge */
                user-select: none;
                /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

                &:hover {
                    background: rgba(255, 255, 255, 0.75);
                    color: black;
                }

                h4 {
                    margin: 0px;
                }

                img {
                    max-width: 100%;
                    margin-bottom: 10px;
                }

                @media(min-width: 480px) {
                    width: 20%;
                }

                @media(min-width: 1400px) {
                    width: 15%;
                }
            }
        }
    }

    .contenedorrellenos {
        display: flex;
        justify-content: center;
        width: 100%;

        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0px;
            justify-content: center;

            li {
                width: 50%;
                margin: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: rgba(0, 0, 0, 0.281);
                padding: 20px;
                border-radius: 10px;
                border: 2px solid rgba(255, 255, 255, 0.233);
                justify-content: flex-end;
                transition: 0.5s;
                -webkit-touch-callout: none;
                /* iOS Safari */
                -webkit-user-select: none;
                /* Safari */
                -khtml-user-select: none;
                /* Konqueror HTML */
                -moz-user-select: none;
                /* Old versions of Firefox */
                -ms-user-select: none;
                /* Internet Explorer/Edge */
                user-select: none;
                /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

                &:hover {
                    background: rgba(255, 255, 255, 0.75);
                    color: black;
                }

                h4 {
                    margin: 0px;
                }

                img {
                    max-width: 100%;
                    margin-bottom: 10px;
                }

                @media(min-width: 0px) {
                    width: 80%;
                }

                @media(min-width: 480px) {
                    width: 33%;
                }

                @media(min-width: 1400px) {
                    width: 15%;
                }
            }
        }
    }

    h1 {
        margin-bottom: 5px;
        text-align: center;
    }

    h3 {
        margin-top: 0px;
        font-weight: normal;
        text-align: center;
    }

    button {
        font-size: 1.5rem;
        padding: 20px;

        i {
            margin-right: 10px;
        }
    }
}
</style>
