<template>
    <div id="contenedorInicio">
        <div class="texto">
            <img src="@/assets/img/roscon-de-reyes.png" class="fa-bounce" />
            <h1>¡Nos alegra verte por aquí otro año más!</h1>
            <h3>Por si el año pasado te supo a poco... ¡ya estamos aquí de nuevo!</h3>
            <router-link v-if="configuracion.pedidos_activos" to="/pedido"><el-button type="warning"><i class="fa-solid fa-bags-shopping"></i> Haz tu
                    pedido</el-button></router-link>
                    <el-alert v-else
    title="Los pedidos ya no están activos"
    type="info"
    description="Ya no es posible realizar tu pedido. ¡Gracias por confiar en nosotros un año más!"
    show-icon
    :closable="false"
  />
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'PaginaInicio',
    data() {
        return {
            configuracion: ''
        }
    },
    mounted() {
        this.init()
    },

    methods: {
        async init() {
            const url = process.env.VUE_APP_BACKEND_URL + '/api/obtenerConfiguracion';

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        // Puedes agregar más encabezados según sea necesario
                    },
                });
                this.configuracion = await response.json();
            } catch (error) {
                // Manejar errores aquí
                console.error('Error en la solicitud:', error.message);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#contenedorInicio {
    display: flex;
    justify-content: center;
    align-items: center;

    .texto {
        text-align: center;
        color: white;
        max-width: 75%;

        img {
            width: 30%;
        }

        h1 {
            margin-bottom: 5px;
        }

        h3 {
            margin-top: 0px;
            font-weight: normal;
        }

        button {
            font-size: 1.5rem;
            padding: 20px;

            i {
                margin-right: 10px;
            }
        }
    }
}
</style>
  